<template>
    <b-card-code no-body title="All users">
        <div class="m-2">
            <!-- Table Top -->
            <div class="d-flex align-items-center justify-content-between mt-2">
                <!-- Per Page -->
                <div cols="6" md="6">
                    <!-- page length -->
                    <b-form-group
                        label="Show"
                        label-cols="5"
                        label-align="left"
                        label-size="sm"
                        label-for="sortBySelect"
                        class="text-nowrap mb-md-0 mr-1"
                    >
                        <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            inline
                            :options="pageOptions"
                        />
                    </b-form-group>
                </div>
                <div>
                    <b-form-input
                        id="input-23"
                        v-model="searchUser"
                        type="text"
                        size="sm"
                        placeholder="Search user"
                        required
                    />
                </div>
            </div>
        </div>

        <!-- Table -->
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="filteredList"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
            <!-- Column: Name -->
            <template #cell(name)="data">
                <router-link :to="{name: 'user-questionnaires', params: {id: data.item.id} }">
                    <span class="font-weight-bold d-block text-nowrap">
                        {{ data.item.name }}
                    </span>
                </router-link>
            </template>

            <!-- Column: Username -->
            <template #cell(username)="data">
                <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.username }}
                </span>
            </template>
            
            <!-- Column: Email -->
            <template #cell(email)="data">
                <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.email }}
                </span>
            </template>

            <template #cell(subscriptions)="data">
                <span class="font-weight-bold d-block text-nowrap" v-if="data.item.subscriptions.length > 0 ">
                    <span v-if="data.item.subscriptions[0].stripe_price == productFullPackage">MHC Premium Plan</span>
                </span>
            </template>

            <template #cell(actions)="data">
                <b-badge variant="light-danger" @click="banUser(data.item.id, data.item.username, data.item.name)" v-b-tooltip.hover.top="'Ban user'">
                    <feather-icon icon="SlashIcon" />
                </b-badge>
                <b-badge variant="light-danger" class="ml-05" @click="removeUser(data.item.id)" v-b-tooltip.hover.top="'Remove user'">
                    <feather-icon icon="Trash2Icon" />
                </b-badge>
                <router-link :to="{name: 'user-update', params: {id: data.item.id} }" v-b-tooltip.hover.top="'Edit user'">
                    <b-badge variant="light-primary" class="ml-05">
                        <feather-icon icon="EditIcon" />
                    </b-badge>
                </router-link>
            </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
</template>

<script>
import store from "@/store/index";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBTooltip,
} from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            data: [],
            searchUser: "",
            perPage: 10,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "name", label: "Full Name" },
                { key: "username", label: "Username" },
                { key: "email", label: "Email" },
                { key: "subscriptions", label: "Subscription" },
                { key: "actions", label: "Actions", class: "text-center" },
            ],

            // Products
            productFullPackage: store.state.app.productFullPackage,
        };
    },
    computed: {
        filteredList() {
            let searchTerm = this.searchUser;
            let countUsers = 0

            return this.data.filter(user => {
                if(user.username == null) {
                    user.username = ""
                }

                if(user.username.toLowerCase().includes(searchTerm.toLowerCase()) || user.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    // Update total rows
                    countUsers++
                    this.totalRows = countUsers
                    
                    return user;
                }
            });       
        }
    },
    methods: {
        getAllUsers() {
            //Return questionnaires list
            this.$http.get("/api/auth/admin/users")
                .then((res) => {
                if(res != undefined) {
                    this.data = res.data
                    // Set the initial number of items
                    this.totalRows = this.data.length;
                }
                })
                .catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },
        removeUser(user_id) {
            this.$bvModal
                .msgBoxConfirm('All data for the selected user will be deleted!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/users/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The user was successfully deleted!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllUsers();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error deleting the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
        banUser(user_id, username, fullName) {        
            this.$bvModal
                .msgBoxConfirm('User ' + fullName + ' (' + username + ') will be banned from the app!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/users/ban/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The user was successfully banned from the app!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllUsers();
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error banning the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
    },
    mounted() {
        this.getAllUsers();
    }
}
</script>

<style scoped>

</style>